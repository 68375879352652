import { createAction, createReducer } from 'redux-act'
import Immutable from 'seamless-immutable'

//
// Initial state
//
const initialState = {
  isInit: false,
  langKey: null,
  openVideoUid: null,
  cookiesSettings: false,
  popinCookiesStatement: false,
  popinInformations: false,
  popinAbout: false,
}

//
// Actions
//
export const actions = {
  init: createAction('App: init'),
  setIsInit: createAction('App: setIsInit', isInit => isInit),
  setLangKey: createAction('App: setLangKey', langKey => langKey),
  setVideoIdModalOpen: createAction('App: setVideoModalOpen', isOpen => isOpen),
  openCookiesSettings: createAction('App: Open cookies settings'),
  closeCookiesSettings: createAction('App: Close cookies settings'),
  openPopinCookiesStatement: createAction('App: Open popin cookies statement'),
  closePopinCookiesStatement: createAction('App: Close popin cookies statement'),
  openPopinInformations: createAction('App: Open popin Informations'),
  closePopinInformations: createAction('App: Close popin Informations'),
}

//
// Reducer
//
export const reducer = createReducer(
  {
    [actions.setIsInit]: (state, isInit) => Immutable.merge(state, { isInit }),
    [actions.setLangKey]: (state, langKey) => Immutable.merge(state, { langKey }),
    [actions.setVideoIdModalOpen]: (state, id) => Immutable.merge(state, { openVideoUid: id }),
    [actions.openCookiesSettings]: state => Immutable.merge(state, { cookiesSettings: true }),
    [actions.closeCookiesSettings]: state => Immutable.merge(state, { cookiesSettings: false }),
    [actions.openPopinCookiesStatement]: state =>
      Immutable.merge(state, { popinCookiesStatement: true }),
    [actions.closePopinCookiesStatement]: state =>
      Immutable.merge(state, { popinCookiesStatement: false }),
    [actions.openPopinInformations]: state => Immutable.merge(state, { popinInformations: true }),
    [actions.closePopinInformations]: state => Immutable.merge(state, { popinInformations: false }),
  },
  initialState
)

//
// Selectors
//
const root = state => state.app
const isInit = state => root(state).isInit
const langKey = state => root(state).langKey
const openVideoUid = state => root(state).openVideoUid
const cookiesSettings = state => root(state).cookiesSettings
const popinCookiesStatement = state => root(state).popinCookiesStatement
const popinInformations = state => root(state).popinInformations

export const selectors = {
  isInit,
  langKey,
  openVideoUid,
  cookiesSettings,
  popinCookiesStatement,
  popinInformations,
}
