import { all, put, takeLatest, select } from 'redux-saga/effects'
import { actions as AppActions, selectors as AppSelectors } from './redux'

export default class AppSagas {
  static *init() {
    const isInit = yield select(AppSelectors.isInit)
    if (!isInit) {
      yield all([put(AppActions.setIsInit(true))])
    }
  }

  static *loop() {
    yield all([takeLatest(AppActions.init.getType(), this.init)])
  }
}
