// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-containers-code-index-js": () => import("./../../../src/containers/Code/index.js" /* webpackChunkName: "component---src-containers-code-index-js" */),
  "component---src-containers-game-error-index-js": () => import("./../../../src/containers/GameError/index.js" /* webpackChunkName: "component---src-containers-game-error-index-js" */),
  "component---src-containers-game-index-js": () => import("./../../../src/containers/Game/index.js" /* webpackChunkName: "component---src-containers-game-index-js" */),
  "component---src-containers-game-overload-index-js": () => import("./../../../src/containers/GameOverload/index.js" /* webpackChunkName: "component---src-containers-game-overload-index-js" */),
  "component---src-containers-game-wait-index-js": () => import("./../../../src/containers/GameWait/index.js" /* webpackChunkName: "component---src-containers-game-wait-index-js" */),
  "component---src-containers-grid-index-js": () => import("./../../../src/containers/Grid/index.js" /* webpackChunkName: "component---src-containers-grid-index-js" */),
  "component---src-containers-homepage-index-js": () => import("./../../../src/containers/Homepage/index.js" /* webpackChunkName: "component---src-containers-homepage-index-js" */),
  "component---src-containers-looks-index-js": () => import("./../../../src/containers/Looks/index.js" /* webpackChunkName: "component---src-containers-looks-index-js" */),
  "component---src-containers-not-found-index-js": () => import("./../../../src/containers/NotFound/index.js" /* webpackChunkName: "component---src-containers-not-found-index-js" */),
  "component---src-containers-onboarding-index-js": () => import("./../../../src/containers/Onboarding/index.js" /* webpackChunkName: "component---src-containers-onboarding-index-js" */),
  "component---src-containers-page-index-js": () => import("./../../../src/containers/Page/index.js" /* webpackChunkName: "component---src-containers-page-index-js" */),
  "component---src-containers-video-index-js": () => import("./../../../src/containers/Video/index.js" /* webpackChunkName: "component---src-containers-video-index-js" */),
  "component---src-containers-vr-index-js": () => import("./../../../src/containers/VR/index.js" /* webpackChunkName: "component---src-containers-vr-index-js" */)
}

