import bugsnagReact from '@bugsnag/plugin-react'
import React from 'react'

import bugsnagClient from '../../configuration/bugsnag'

let BugsnagWrapper = null

if (bugsnagClient) {
  bugsnagClient.use(bugsnagReact, React)
  BugsnagWrapper = bugsnagClient.getPlugin('react')
}

export default BugsnagWrapper
