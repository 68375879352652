/* eslint-disable no-console */
const bugsnag = require('@bugsnag/js')

const browserIgnoreErrors = []

const productionDisableCallback = callback => {
  if (process.env.APP_ENV !== 'production') {
    callback()
  }
}

const bugsnagLoggerPrefix = `[bugsnag:${process.env.APP_ENV}]: `

const bugsnagLogger = {
  debug: e => productionDisableCallback(() => console.debug(`${bugsnagLoggerPrefix}${e}`)),
  info: e => productionDisableCallback(() => console.info(`${bugsnagLoggerPrefix}${e}`)),
  warn: e => productionDisableCallback(() => console.warn(`${bugsnagLoggerPrefix}${e}`)),
  error: e => productionDisableCallback(() => console.error(`${bugsnagLoggerPrefix}${e}`)),
}

let bugsnagClient

if (process.env.BUGSNAG_API_KEY) {
  bugsnagClient = bugsnag({
    apiKey: process.env.BUGSNAG_API_KEY,
    appVersion: process.env.APP_VERSION ? process.env.APP_VERSION : 'undefined',
    logger: bugsnagLogger,
    releaseStage: process.env.APP_ENV,
    beforeSend: report => {
      // Disable reporting of errors with a given error message
      if (browserIgnoreErrors.some(ignoreError => ignoreError.test(report.errorMessage))) {
        report.ignore()
      }
    },
  })
}

module.exports = bugsnagClient
